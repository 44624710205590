import React from 'react'

type Props = { name: string; checked?: boolean }

export default function AccountProductCardTag({ name, checked }: Props) {
  const textColor = checked ? 'text-bung-400' : 'text-fizz-100'
  const bgColor = checked ? 'bg-bung-800' : 'bg-fizz-800'

  return (
    <div
      className={`flex flex-row items-center px-2 py-1 rounded-full shrink-0 ${textColor} ${bgColor} text-xs`}
    >
      {name}
    </div>
  )
}
