import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import {
  deviceDataState,
  isOpenSignUpModalState,
  isOpenStripePaymentState,
  isOpenWalletState,
  paymentFromState,
  promoState,
  selectedAccountProductState,
  stripeRadarSessionState,
} from '../../../../common/atoms/checkout'
import { usePostlolCheckout } from '../../../../hooks/accounts/usePostlolCheckout'
import { selectedPaymentState } from '../../../../common/atoms/atoms'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { SessionStatusEnum } from '../../../../common/enums/session-status.enum'
import DefaultCreditCard from '../../../checkout/DefaultCreditCard'
import NoCreditCard from '../../../checkout/NoCreditCard'
import RightArrowIcon from '../../../../public/static/arrow-right-small-dark.svg'
import Image from 'next/image'
import PromoIcon from '../../../../public/static/promo.svg'
import TrashIcon from '../../../../public/static/trash.svg'
import { clearStoredPromoCodeV2 } from '../../../../hooks/cache/promo-code-storage-v2'
import { usePromoCode } from '../../../../hooks/promos/usePromoCode'
import { useBlockPromoCode } from '../../../../hooks/promos/useBlockPromoCode'

type Props = {}

export default function AccountCheckoutSection({}: Props) {
  const [accountProduct] = useRecoilState(selectedAccountProductState)
  const [, setIsOpenWallet] = useRecoilState(isOpenWalletState)
  const [deviceData] = useRecoilState(deviceDataState)
  const [radarSessionId] = useRecoilState(stripeRadarSessionState)
  const { mutate, isLoading, isSuccess } = usePostlolCheckout()
  const [selectedPayment] = useRecoilState(selectedPaymentState)
  const router = useRouter()
  const [, setIsOpenSignUp] = useRecoilState(isOpenSignUpModalState)
  const [, setIsOpenStripeAddPayment] = useRecoilState(isOpenStripePaymentState)
  const [, setPaymentFromState] = useRecoilState(paymentFromState)
  const { status } = useSession()
  const { hasPromoCode } = usePromoCode()
  const [promoCode, setPromoCode] = useRecoilState(promoState)
  const { mutate: deletePromoCode, isSuccess: deletePromoCodeSucces } =
    useBlockPromoCode()
  useEffect(() => {
    if (isSuccess) {
      router.push('/account/')
    }
  }, [isSuccess])
  return (
    <div className={'w-full '}>
      <div
        className={
          'items-center flex flex-row justify-between cursor-pointer select-none w-full'
        }
        onClick={() => {
          setIsOpenWallet(true)
        }}
      >
        <div className={'w-full'}>
          {selectedPayment ? (
            <DefaultCreditCard payment={selectedPayment} theme={'dark'} />
          ) : (
            <NoCreditCard theme={'dark'} />
          )}
        </div>

        <div className={'relative h-3 w-4'}>
          <Image
            src={RightArrowIcon}
            fill={true}
            className={'object-contain'}
            alt={'Right arrow icon'}
          />
        </div>
      </div>

      {hasPromoCode() && promoCode?.type === 'marketplace_only' && (
        <div
          className={'flex flex-col w-full mt-2 cursor-pointer'}
          onClick={() => {
            setIsOpenWallet(true)
          }}
        >
          <div className={'mt-1 flex flex-row items-center justify-between'}>
            <div className={'flex flex-row items-center'}>
              <div className={'relative h-6 w-6 rounded-full'}>
                <Image
                  src={PromoIcon}
                  className={'rounded-full object-contain'}
                  fill={true}
                  alt={'Promo icon'}
                />
              </div>

              <p className={'text-body-semibold text-color-gray5 ml-2'}>
                {promoCode?.type && promoCode.type.includes('referrals')
                  ? 'Referral Code Applied'
                  : 'Promo Code Applied'}
              </p>
            </div>

            <div className={'relative h-6 w-6 cursor-pointer'}>
              <Image
                src={TrashIcon}
                fill={true}
                className={'object-contain'}
                alt={'Trash icon'}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (promoCode) {
                    if (promoCode.type === 'marketplace_only') {
                      deletePromoCode(promoCode.id)
                    }
                  }
                  clearStoredPromoCodeV2()
                  setPromoCode(null)
                }}
              />
            </div>
          </div>

          <p className={'ml-8 mt-1 text-xs font-normal text-[#1DCEA4]'}>
            {promoCode?.name}
          </p>
        </div>
      )}

      <button
        className={'btn-v2-primary w-full mt-4 hover:opacity-80'}
        disabled={isLoading}
        onClick={(e) => {
          if (status === SessionStatusEnum.UNAUTHENTICATED) {
            setIsOpenSignUp(true)
            return
          }

          if (selectedPayment === null || selectedPayment === undefined) {
            setPaymentFromState('checkout')
            setIsOpenStripeAddPayment(true)
            return
          }

          mutate({
            id: accountProduct?.id ?? '',
            deviceData: deviceData,
            radarSessionId: radarSessionId,
          })
        }}
      >
        {isLoading && <span className='loading loading-spinner' />}
        ORDER • ${accountProduct?.price.toFixed(2) ?? ''}
      </button>
    </div>
  )
}
