import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import AccountInfoSection from '../AccountInfoSection'
import Image from 'next/image'
import CloseIcon from '../../../../../public/static/close_button_black.svg'

type Props = {
  isOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export default function AccountInfoModal({ isOpenState }: Props) {
  const [isOpen, setIsOpen] = isOpenState

  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-40'
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='modal-mask' />
          </Transition.Child>

          <div className='fixed inset-0 z-30 overflow-y-auto'>
            <div className='flex min-h-screen overflow-y-auto items-center justify-center p-4 text-center  sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='bg-fizz-900 relative w-full transform overflow-hidden rounded-xl flex flex-col flex-grow min-h-screen text-left shadow-xl transition-all md:w-128 pt-16 pb-6 px-5'>
                  <div className='absolute right-4 top-4 '>
                    <button
                      type='button'
                      className='focus:outline-none focus:ring-0'
                      onClick={() => setIsOpen(false)}
                    >
                      <Image
                        src={CloseIcon}
                        alt={'Close icon'}
                        width={32}
                        height={32}
                        className={'object-contain group-hover:opacity-60'}
                      />
                    </button>
                  </div>
                  <AccountInfoSection />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
