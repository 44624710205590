import { useSession } from 'next-auth/react'
import { useMutation } from '@tanstack/react-query'

import { postMarketplaceAccountCheckout } from '../../api/account/account'
import { LolAccountCheckoutRequest } from '../../types/account/lol-account-checkout-request'

export function usePostlolCheckout() {
  const { data } = useSession()

  return useMutation(async (request: LolAccountCheckoutRequest) =>
    //@ts-ignore
    postMarketplaceAccountCheckout(data?.accessToken, request),
  )
}
