import Image from 'next/image'
import React, { useState } from 'react'

import ClockGrayIcon from '../../../public/static/clock_gray.svg'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { LolAccountResponse } from '../../../types/account/lol-account-response'
import AccountInfoModal from '../../home/mode/account/modal/AccountInfoModalV2'
import AccountProductCardTag from './AccountProductCardTag'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(localizedFormat)

type Props = {
  accountProduct: LolAccountResponse
  isLoading?: boolean
  checked?: boolean
  onClick?: () => void
}

export default function AccountProductCard({
  accountProduct,
  isLoading,
  checked,
  onClick,
}: Props) {
  const price = accountProduct.price ?? 0
  const modalState = useState(false)
  const [isOpen, setIsOpen] = modalState

  return (
    <div
      key={'account:' + accountProduct.id}
      className={` cursor-pointer group ${checked ? 'bg-[#212900]' : ''}`}
      onClick={(e) => {
        onClick && onClick()
      }}
    >
      {isOpen && accountProduct && (
        <AccountInfoModal isOpenState={modalState} />
      )}

      <div className={'flex flex-col px-3 py-3'}>
        <div className={'flex w-full flex-row items-center justify-between '}>
          <div
            className={`h-4 w-4 shrink-0 select-none rounded-full   ${
              checked
                ? 'border-3 border-bung-400 bg-[#03060D]'
                : 'border-2 border-darul-500'
            }`}
          />

          <div className={'ml-6 shrink-0 '}>
            <div className={'relative  h-9 w-9'}>
              <Image
                src={
                  accountProduct.imageUrl ??
                  process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                }
                alt={accountProduct.title ?? ''}
                fill={true}
                className={`${accountProduct?.imageUrl ? 'object-cover' : 'object-contain'}`}
              />
            </div>
          </div>

          <div
            className={
              'ml-3.5 mr-3 flex w-full flex-row items-start justify-between gap-1.5'
            }
          >
            <div className={'flex flex-col'}>
              <div className={'flex flex-row items-center gap-1'}>
                <h2 className={'text-lg-medium text-color-gray5'}>
                  {accountProduct.title}
                </h2>
              </div>

              <h3
                className={'text-secondary-normal text-[#C8CFDE] line-clamp-3'}
              >
                {accountProduct.description}
              </h3>

              <div
                className={'md:flex flex-row items-center gap-1 mt-1 hidden'}
              >
                <AccountProductCardTag
                  checked={checked}
                  name={`${accountProduct.champions ?? 0} champions`}
                />

                <AccountProductCardTag
                  checked={checked}
                  name={`${accountProduct.skins ?? 0} skins`}
                />

                {/*todo : 7 Mythic+skins*/}
                {/*<AccountProductCardTag checked={checked} name={''} />*/}
              </div>

              <p
                className={'text-[#81A900] text-secondary-medium md:hidden'}
                onClick={(e) => {
                  e.preventDefault()
                  setIsOpen(true)
                }}
              >
                See details ›
              </p>
            </div>

            <div className={'flex shrink-0 flex-col items-end truncate'}>
              <span className={'text-body-medium text-color-gray5'}>
                <div>
                  <span className={'mr-0.5 text-xs'}>$</span>
                  {price.toFixed(2)}
                </div>
              </span>

              <div className={'mt-1 flex flex-row items-center gap-1'}>
                <div className={'relative h-3 w-3'}>
                  <Image
                    src={ClockGrayIcon}
                    fill={true}
                    className={'object-contain'}
                    alt={'Clock icon'}
                  />
                </div>
                <p className={' text-secondary-normal truncate text-[#C8CFDE]'}>
                  Instant
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
