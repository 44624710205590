import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { getPurchasedMarketplaceAccounts } from '../../api/account/account'
import { LolAccountResponse } from '../../types/account/lol-account-response'

export function useGetPurchasedMarketplaceAccounts() {
  const { data } = useSession()

  return useQuery<LolAccountResponse[]>(
    ['Lol Accounts'],
    //@ts-ignore
    async () => getPurchasedMarketplaceAccounts(data?.accessToken),
  )
}
