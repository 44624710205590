import React from 'react'
import AccountSection from '../../products/account/AccountSection'
import AccountCheckoutSection from './play/AccountCheckoutSection'

export default function AccountMode() {
  return (
    <div className={'flex flex-col w-full gap-3.5'}>
      <div className={'h-fit w-full md:h-fit md:overflow-visible'}>
        <AccountSection />
      </div>
      <h2 className={'text-body-medium text-[#F87B20]'}>Payment</h2>
      <AccountCheckoutSection />
    </div>
  )
}
