import React, { useEffect, useState, useMemo } from 'react'
import AccountProductCard from './AccountProductCard'
import { useGetPurchasedMarketplaceAccounts } from '../../../hooks/accounts/useGetPurchasedMarketplaceAccounts'
import { labelMotion, labelTransition } from '../../../common/motion/checkout'
import { motion } from 'framer-motion'
import { useRecoilState } from 'recoil'
import { selectedAccountProductState } from '../../../common/atoms/checkout'

export default function AccountSection() {
  const { data, isLoading } = useGetPurchasedMarketplaceAccounts()
  const [selectedAccountProduct, setSelectAccountProduct] = useRecoilState(
    selectedAccountProductState,
  )
  // const [selectedServer, setSelectedServer] = useState<string>('Select Region')

  // const serverOptions: Option[] = useMemo(() => {
  //   const servers = [
  //     'Select Region',
  //     ...Array.from(new Set(data?.map((account) => account.server) || [])),
  //   ]
  //   return servers.map((server) => ({ name: server, value: server }))
  // }, [data])

  // const filteredData = useMemo(() => {
  //   if (!data) return []
  //   return selectedServer === 'Select Region'
  //     ? data
  //     : data.filter((account) => account.server === selectedServer)
  // }, [data, selectedServer])

  // useEffect(() => {
  //   if (selectedAccountProduct === null && filteredData.length > 0) {
  //     setSelectAccountProduct(filteredData[0])
  //   }
  // }, [filteredData, selectedAccountProduct, setSelectAccountProduct])

  if ((data?.length ?? 0) === 0) {
    return null
  }

  return (
    <motion.div
      variants={labelMotion}
      transition={labelTransition}
      initial='hidden'
      animate='visible'
      className={'w-full'}
    >
      <div className={'flex w-full flex-col gap-1.5 mt-1'}>
        <div
          className={
            'text-body-medium flex flex-row items-center gap-3 text-white capitalize justify-between'
          }
        >
          <h3>Select Account</h3>
          {/*<FilterSelector*/}
          {/*  selectorStyle={'border border-fizz-500 bg-transparent z-10'}*/}
          {/*  optionStyle={'border border-darul-500 bg-fizz-600'}*/}
          {/*  selectorTextStyle={'text-fizz-100 text-body-bold'}*/}
          {/*  activeStyle={'bg-bult-1000 text-white'}*/}
          {/*  options={serverOptions}*/}
          {/*  currentOption={*/}
          {/*    serverOptions.find((option) => option.value === selectedServer) ||*/}
          {/*    serverOptions[0]*/}
          {/*  }*/}
          {/*  setCurrentOption={(option) => setSelectedServer(option.value)}*/}
          {/*/>*/}
        </div>
        {/*<<<<<<< HEAD*/}
        {/*        {filteredData.map((product: LolAccountResponse) => (*/}
        {/*          <AccountProductCard*/}
        {/*            key={product.id}*/}
        {/*            accountProduct={product}*/}
        {/*            isLoading={isLoading}*/}
        {/*            checked={selectedAccountProduct?.id === product.id}*/}
        {/*            onClick={() => setSelectAccountProduct(product)}*/}
        {/*          />*/}
        {/*        ))}*/}
        {/*=======*/}

        {data?.map((product, index) => {
          return (
            <AccountProductCard
              key={product.id}
              accountProduct={product}
              isLoading={isLoading}
              checked={selectedAccountProduct?.id === product.id}
              onClick={() => {
                setSelectAccountProduct(product)
              }}
            />
          )
        })}
        {/*>>>>>>> dev*/}
      </div>
    </motion.div>
  )
}
